<template>
  <div class="tags" v-if="showTags">
    <ul>
      <li
        class="tags-li"
        v-for="(item, index) in tagsList"
        :key="index"
        :class="{'active': isActive(item.path)}"
        :style="{background:`${isActive(item.path) ? subjectColor : ''}`}"
      >
        <router-link :to="item.path" class="tags-li-title">{{item.title}}</router-link> <!-- @click.native="flushCom" -->
        <span class="tags-li-icon" @click="closeTags(index)">
          <i class="el-icon-close"></i>
        </span>
      </li>
    </ul>
    <div class="tags-close-box pt10">
      <el-dropdown @command="handleTags" style='margin-right:23px'>
        <el-button size="mini" style="border-radius: 2px !important;border: 1px solid #51CBCD;color: #51CBCD;">
          页面选项
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu size="small" slot="dropdown">
          <el-dropdown-item command="other">关闭其他</el-dropdown-item>
          <el-dropdown-item command="all">关闭所有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import bus from "../../service/bus";
export default {
  name: "commonTags",
  data() {
    return {
      tagsList: [],
    };
  },
  
  created() {
    this.setTags(this.$route);
  },
  methods: {
    // 判断当前路由是否匹配当前tag
    isActive(path) {
      //详情页携带了id 所以需要去掉id再进行判断
      let str =  JSON.parse(JSON.stringify(this.$route.fullPath)),
      routePath = this.getStr(str)
      let cstr =  JSON.parse(JSON.stringify(path)),
      currentRoutePath = this.getStr(cstr)
      return currentRoutePath === routePath;
    },

    // 关闭标签
    closeTags(index) {
      let currentItem = this.tagsList.splice(index, 1)[0];
      let item = this.tagsList[index]
        ? this.tagsList[index]
        : this.tagsList[index - 1];
      if (item) {
        currentItem.path === this.$route.fullPath &&
          this.$router.push(item.path);
      } else {
        this.$router.push("/dashboard");
      }
    },

    // 设置标签
    setTags(route) {
      let str = JSON.parse(JSON.stringify(route.fullPath)),
      routePath = this.getStr(str)

      if (this.tagsList.length > 0) {//length > 0
      let isExist = this.tagsList.find((item) => {
        let cstr = JSON.parse(JSON.stringify(item.path)),
        currentRoutePath = this.getStr(cstr)
        return currentRoutePath == routePath
      }); 
        if (this.tagsList.length >= 15) {
          this.tagsList.shift();
        }
        if(!isExist){//当没有重复的页面时
          this.tagsList.push({
            title: route.meta.title,
            path: route.fullPath,
          });
        }else{//当有重复的页面时
          this.tagsList.forEach((item)=>{
             let cstr = JSON.parse(JSON.stringify(item.path)),
             currentRoutePath = this.getStr(cstr)
             if(currentRoutePath == routePath){
               item.path = route.fullPath
             }
          })

        }
      }else{
        this.tagsList.push({//length < 0
          title: route.meta.title,
          path: route.fullPath,
        });
      }

      console.log('this.tagsList',this.tagsList)
      bus.$emit("tags", this.tagsList);
    },

    // 关闭其他选项
    handleCloseOther() {
      let currentItem = this.tagsList.filter((item) => {
        return item.path === this.$route.fullPath;
      });
      this.tagsList = currentItem;
    },

    // 关闭所有选项
    handleCloseAll() {
      this.tagsList = [];
      this.$router.push("/dashboard");
    },

    // 标签选项
    handleTags(val) {
      val === "other" ? this.handleCloseOther() : this.handleCloseAll();
    },
    getStr(string){ 
      var str_before = string.split('?')[0]; 
      return str_before
    },
    // flushCom() {
    //   this.$router.go(0)
    // }
  },
  watch: {
    $route(newValue) {
      this.setTags(newValue);
    },
  },
  computed: {
    showTags() {
      return this.tagsList && this.tagsList.length > 0;
    },
    // 主题颜色
    subjectColor(){
      return this.$store.state.tagColor
    },
  },
};
</script>

<style scoped>
.tags {
  padding-top: 10px;
  position: relative;
  height: 50px;
  overflow: hidden;
  z-index: 1;
  background: #fff;
  padding-right: 120px;
  box-shadow: 0px 2px 6px rgba(115, 127, 128, 0.12);
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.tags-li {
  float: left;
  margin: 5px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 26px;
  line-height: 28px;
  border: 1px solid #e9eaec;
  background: #fff;
  padding: 0 5px 0 12px;
  vertical-align: middle;
  color: #666;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  border-radius: 2px;
}

.tags-li .active .tags-li-icon {
  background-color: red;
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li.active {
  color: #fff;
}

.tags-li-title {
  float: left;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
}

.tags-li.active .tags-li-title {
  color: #fff;
}

.tags-close-box {
  position: absolute;
  right: 5px;
  top: 0;
  box-sizing: border-box;
  text-align: center;
  height: 30px;
  z-index: 10;
}

/* .tags-li.active {
  border: 1px solid #6190e8;
  background-color: #6190e8;
} */
</style>
