<template>
  <div class="sidebar">
    <el-menu
      :default-active="onRoute"
      class="sidebar-el-menu"
      :collapse="collapse"
      :background-color="subjectColor"
      text-color="#17191A"
      active-text-color="#51CBCD"
      router
      unique-opened
    >
      <template v-for="item in items">
        <template v-if="item.children">
          <el-submenu :index="item.index"
                      :key="item.id"
                      v-if='item.visible'>
            <template slot="title" >
              <!-- 预留字体图标 -->
              <svg
                class="icon"
                aria-hidden="true"
                style="margin-right: 12px; font-size: 18px"
              >
                <use :xlink:href="item.icon"></use>
              </svg>
              <span slot="title" class="title">{{ item.label }} </span>
            </template>
            <!--二级菜单 strat-->
              <template v-for="subItem in item.children">
                <template v-if="subItem.visible">
                  <el-submenu
                    v-if="subItem.children"
                    :index="subItem.index"
                    :key="subItem.id"
                    class="title"
                  >
                    <template slot="title" class="title">{{
                      subItem.label
                    }}</template>
                    <el-menu-item
                      v-for="(threeItem) in subItem.children"
                      :key="threeItem.id"
                      :index="threeItem.index"
                      class="title"
                      >{{ threeItem.label }}</el-menu-item
                    >
                  </el-submenu>
                  <template v-else>
                    <el-menu-item
                      :index="subItem.index"
                      :key="subItem.id"
                      class="title pl50"
                      >{{ subItem.label }}
                    </el-menu-item>
                  </template>

                </template>
              </template>
            <!--二级菜单 end-->
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.id" class="title" v-if='item.visible'>
            <!-- 预留字体图标 -->
            <svg
              class="icon"
              aria-hidden="true"
              style="margin-right: 7px; font-size: 18px"
            >
              <use :xlink:href="item.icon"></use>
            </svg>
            <span slot="title" class="title">
              {{ item.label }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from "../../service/bus";
import { mapState } from 'vuex';
import { filterUserRoot } from '@/utils/utils.js'
export default {
  name: "commonSidebar",
  data() {
    return {
      collapse: false,
      isMain:false,
      member:false,
      isHaveMall: false,
      isHaveNfc: false,
      info:'',
      items: [
        {
          icon: "#icon-dashboard",
          index: "dashboard",
          title: "工作台",
          member:true
        },
        {
          icon: "#icon-team",
          index: "customer",
          title: "客户管理",
          member:true,
          type:'customer',
          subs: [
            // {
            //   index: "customerInput",
            //   title: "客户录入",
            //   memberRoot:true,
            // },
            {
              index: "customerManagement",
              title: "客户管理",
              memberRoot:true,
            },
            // {
            //   index: "totalCustomer",
            //   title: "总客户池",
            //   memberRoot:true,
            //   id:'01',
            // },
            // {
            //   index: "visitCustomers",
            //   title: "访问客户",
            // },
          ],
        },
        {
          icon: "#icon-bank",
          index: "companyManagement",
          title: "企业管理",
          member:true,
          id:'02',
          subs: [
            {
              index: "corporateInformation",
              title: "企业信息",
              memberRoot:true,
              id: '020'
            },
            {
              index: "enterpriseMember",
              title: "企业成员",
              id: '021',
              memberRoot:true,
            },
            {
              index: "advancedForm",
              title: "自定义反馈表",
              memberRoot:true,
              id: '022'
            },
            // {
            //   index: "websiteSettings",
            //   title: "官网设置",
            // },
            {
              index: "advertisingManagement",
              title: "广告图管理",
              memberRoot:true,
              id: '023'
            },
          ],
        },
        {
          icon: "#icon-file-text",
          index: "orderManagement",
          title: "订单管理",
          member:true,
          id:'03',
          subs: [
            {
              index: "orderManagement",
                title: "订单管理",
                memberRoot:true,
                id: '030'
              },
            {
              index: "evaluateManagement",
              title: "评价管理",
              memberRoot:true,
              id: '031'
            },
            {
              index: "noticeManagement",
              title: "通知管理",
              memberRoot:true,
              id: '032'
            }
          ]
        },
        {
          icon: "#icon-shopping",
          index: "merchandiseManagement",
          title: "商品管理",
          member:true,
          id:'04',
          subs: [
            {
              index: "management",
              title: "商品管理",
              memberRoot:true,
              id: '040'
            },
            {
              index: "appointmentManagement",
              title: "预约管理",
              memberRoot:true,
              id: '041'
            },
            // {
            //   index: "freightManagement",
            //   title: "运费管理",
            // },
            // {
            //   index: "noticeManagement",
            //   title: "通知管理",
            //   memberRoot:true,
            //   id: '042'
            // }
          ],
        },
        {
          icon: "#icon-gift",
          index: "marketingActivities",
          title: "营销活动",
          member:true,
          id:'05',
          subs: [
            {
              index: "distribution",
              title: "分销",
              id: '050',
              memberRoot:true,
              subs: [
                {
                  index: "distributionStatistics",
                  title: "分销统计",
                  memberRoot:true,
                },
                {
                  index: "personnelApplication",
                  title: "人员申请",
                  memberRoot:true,
                },
                {
                  index: "distributionLevel",
                  title: "分销等级",
                  memberRoot:true,
                },
                {
                  index: "distributionOrder",
                  title: "分销订单",
                  memberRoot:true,
                },
                {
                  index: "accountTransfer",
                  title: "到账流水",
                  memberRoot:true,
                },
              ],
            },
            {
              index: "joinGroup",
              title: "拼团",
              memberRoot:true,
              id: '051',
              subs: [
                {
                  index: "productList",
                  title: "拼团商品列表",
                  memberRoot:true,
                },
                {
                  index: "listParticipants",
                  title: "参团列表",
                  memberRoot:true,
                },
              ],
            },
            {
              index: "spike",
              title: "秒杀",
              memberRoot:true,
              id: '052',
              subs: [
                {
                  index: "spikeList",
                  title: "秒杀商品列表",
                  memberRoot:true,
                },
              ],
            },
            {
              index: "couponManagement",
              title: "优惠券管理",
              id: '053',
              memberRoot:true,
              subs: [
                {
                  index: "couponList",
                  title: "优惠券列表",
                  memberRoot:true,
                },
              ],
            },
          ],
        },
        {
          icon: "#icon-creditcard",
          index: "financialManagement",
          title: "财务管理",
          member:true,
          id:'06',
          subs: [
            {
              index: "commissionManagement",
              title: "佣金管理",
              memberRoot:true,
            },
            // {
            //   index: "moneyManagement",
            //   title: "资金管理",
            // },
          ],
        },
        // {
        //   icon: "#icon-cluster",
        //   index: "agentManage",
        //   title: "代理企业",
        //   member:true,
        //   isAgent:true,
        // },
        {
          icon: "#icon-solution",
          index: "articleManagement",
          title: "获客文章管理",
          member:true,
          id:'07',
          subs: [
            {
              index: "customerArticles",
              title: "获客文章",
              memberRoot:true,
              id: '070'
            },
            {
              index: "employeeData",
              title: "员工数据",
              memberRoot:true,
              id: '071'
            },
            {
              index: "visitorData",
              title: "访客数据",
              memberRoot:true,
              id: '072'
            },
          ],
        },
        {
          icon: "#icon-video",
          index: "videoManage",
          title: "短视频管理",
          member:true,
          id:'08',
        },
        // {
        //   icon: "#icon-key",
        //   index: "addedServices",
        //   title: "增值服务",
        //   member:true,
        //   id:'09',
        //   subs: [
        //     {
        //       index: "myNeeds",
        //       title: "我的需求",
        //       memberRoot:true,
        //     },
        //     {
        //       index: "demandCheck",
        //       title: "需求审核",
        //       root:true,
        //       administrator:false,
        //       memberRoot:true,
        //       id: '090'
        //     },
        //     {
        //       index: "demandContactsCheck",
        //       title: "人脉集市审核",
        //       root:true,
        //       administrator:false,
        //       memberRoot:true,
        //       id: '092'
        //     },
        //     {
        //       index: "demandProtuctsCheck",
        //       title: "产品中心审核",
        //       root:true,
        //       administrator:false,
        //       memberRoot:true,
        //       id: '091'
        //     },
        //     {
        //       index: "demandPublishSetting",
        //       title: "发布设置",
        //       memberRoot:true,
        //       administrator:false,
        //       root:true,
        //       id: '093'
        //     },
        //     {
        //       index: "demandReport",
        //       title: "举报处理",
        //       memberRoot:true,
        //       administrator:false,
        //       root:true,
        //       id: '090'
        //     },
        //     {
        //       index: "demandCustomize",
        //       title: "自定义设置",
        //       memberRoot:true,
        //       administrator:false,
        //       root:true,
        //       id: '094'
        //     },

        //   ],
        // },
        // {
        //   icon:'#icon-share',
        //   index: "sharingPromotion",
        //   title: "分享推广管理",
        //   member:true,
        //   id:'010',
        //   subs: [
        //     {
        //       index: "posterAtlas",
        //       title: "海报图集",
        //       memberRoot:true,
        //     },
        //     {
        //       index: "clockData",
        //       title: "打卡数据",
        //       memberRoot:true,
        //     },
        //   ],
        // },
        {
          icon: "#icon-api",
          index: "miniProgram",
          title: "商城设置",
          member:true,
          subs: [
            // {
            //   index: "basicSetting",
            //   title: "基础设置",
            //   memberRoot:true,
            // },
            // {
            //   index: "appletConfig",
            //   title: "小程序配置",
            //   // root:true,
            //   memberRoot:true,
            // },
            {
              index: "mallSetting",
              title: "商城设置",
              memberRoot:true,
            },
          ],
        },
        // 模板管理
        {
          icon: "#icon-CI",
          index: "customPage",
          title: "模版管理",
          member:true,
          subs: [
            // {
            //   index: "customPage",
            //   title: "页面模版",
            //   memberRoot:true,
            // },
            {
              index: "customPageList",
              title: "模版列表",
              memberRoot:true,
            },
            {
              index: "createPageList",
              title: "页面列表",
              memberRoot:true,
            },
            {
              index: "configHandleType",
              title: "数据模版",
              memberRoot:true,
            },
            {
              index: "configTemplate",
              title: "标识模版",
              memberRoot:true,
            },
            {
              index: "handleCustomer",
              title: "客户管理",
              memberRoot:true,
            },
          ],
        },
        // 公众号相关
        {
          icon: "#icon-smile",
          index: "publicAbout",
          title: "公众号",
          member:true,
          subs: [
            {
              index: "publicAbout",
              title: "公众号相关",
              memberRoot:true,
            },
          ],
        },
        //任务管理
        {
          icon: "#icon-shopping",
          index: "printManage",
          title: "任务管理",
          member:true,
          subs: [
            {
              index: "taskIndex",
              title: "任务管理",
              memberRoot:true
            },
            {
              index: "specialIndex",
              title: "箱盒任务",
              memberRoot:true
            },
            {
              index: "printIndex",
              title: "设备管理",
              memberRoot:true
            },
            {
              index: "screenIndex",
              title: "打印大屏",
              memberRoot:true
            }
          ],
        },
      ],
    };
  },

  created() {

    // 控制折叠面板
    bus.$on("collapse", (msg) => {
      this.collapse = msg;
      bus.$emit("collapse-content", msg);
    });
    this.info = JSON.parse(localStorage.getItem('info'));
    let initRoute = filterUserRoot() // 根据购买，动态返回菜单

    // 请求接口，获取所有菜单id，动态渲染菜单.没有就设置默认权限
    //根据不同角色，设置默认值
    let arr = this.setInitRoutes(initRoute,this.info.userType)
    console.log('arr', arr)
    let resRoute = this.filterRoute(initRoute, arr)
    this.items = resRoute
    console.log('resRoute', resRoute)
    // /department/queryEmployee
    // this.isHaveMall= JSON.parse(this.info.isHaveMall) // 功能权限
    // this.isMkc = JSON.parse(this.info.isMkc)
    // this.isMkt = JSON.parse(this.info.isMkt)
    // this.isHaveNfc = JSON.parse(this.info.isHaveNfc)

    // if(this.isMkc && (!this.isMkt)) { // 只买防伪码
    //    let delMenu = [
    //     'customer',
    //     'articleManagement',
    //     'videoManage',
    //     'sharingPromotion'
    //   ]
    //   let arr1 = this.items.filter(item => !delMenu.includes(item.index))
    //   if(!this.isMkt) {
    //     arr1.filter(item => item.index === 'companyManagement')[0].subs.splice(1,3)
    //   }
    //   if(!this.isHaveMall) {
    //     let delMenu2 = [
    //       'miniProgram', //小程序设置
    //       'orderManagement', //订单管理
    //       'merchandiseManagement', //商品管理
    //       'marketingActivities', //营销活动
    //       'financialManagement', //财务管理
    //     ]
    //     arr1 = arr1.filter(item => !delMenu2.includes(item.index))
    //   }
    //   this.items = arr1
    // }else if(this.isMkt && (!this.isMkc)) { // 只买防伪码
    //   let delMenu = [
    //     'customPage', //模版管理
    //     'printManage' //任务管理
    //   ]
    //   let arr1 = this.items.filter(item => !delMenu.includes(item.index))

    //   if(!this.isHaveMall) {
    //     let delMenu2 = [
    //       'miniProgram', //小程序设置
    //       'orderManagement', //订单管理
    //       'merchandiseManagement', //商品管理
    //       'marketingActivities', //营销活动
    //       'financialManagement', //财务管理
    //     ]
    //     arr1 = arr1.filter(item => !delMenu2.includes(item.index))
    //   }

    //   this.items = arr1
    // }else if((!this.isMkt) && (!this.isMkc)){ // 都没买
    //   let delMenu3=[
    //       'customer',
    //       'articleManagement',
    //       'videoManage',
    //       'sharingPromotion',
    //       'customPage', //模版管理
    //       'printManage',
    //       'miniProgram', //小程序设置
    //       'orderManagement', //订单管理
    //       'merchandiseManagement', //商品管理
    //       'marketingActivities', //营销活动
    //       'financialManagement',
    //       'publicAbout'
    //   ]

    //   let arr1 = this.items.filter(item => !delMenu3.includes(item.index))
    //   if(!this.isMkt) {
    //     arr1.filter(item => item.index === 'companyManagement')[0].subs.splice(1,3)
    //   }
    //   this.items = arr1
    // }

  },

  computed: {
    ...mapState(['routerArr']),
    // 主题颜色
    subjectColor() {
      return this.$store.state.subjectColor;
    },
    // 路由配置
    onRoute() {
      return this.$route.path.replace("/", "");
    },
    // jurisdiction(){
    //   let arr = [
    //     'sharingPromotion',
    //     'marketingActivities',
    //     'financialManagement',
    //     'articleManagement',
    //     'orderManagement',
    //     'customPage',
    //   ]
    //   return arr
    // },
    // memberJurisdiction(){
    //   let arr = [
    //     'totalCustomer',
    //     'corporateInformation',
    //     'advertisingManagement'
    //   ]
    //   return arr
    // },
  },
  watch:{
    // routerArr(val){
    //   try {
    //     console.log(val,'val')
    //     let than = this
    //       this.items.map(item => {
    //       if(item.id || item.type == 'customer'){//父级
    //         if(val.includes(item.id)){
    //           item.member = true
    //         }else if(item.type == 'customer'){
    //           item.member = true
    //         }else{
    //           item.member = false
    //         }

    //         if(item.subs){
    //           item.subs.map(subItem=>{
    //             if(subItem.id){//子集

    //               if(val.includes(subItem.id)){
    //                 if(subItem.id == '022'){
    //                   than.items[2].member = true
    //                   console.log(than.items,'items')
    //                 }
    //                 if(subItem.id == '0133'){
    //                   console.log('subItem',subItem)
    //                 }
    //                 subItem.memberRoot = true
    //                 if(subItem.administrator == false){
    //                   subItem.root = false
    //                   subItem.administrato = true
    //                 }
    //               }else{
    //                 subItem.memberRoot = false
    //               }
    //             }

    //           })
    //         }
    //       }
    //     })
    //   } catch (error) {
    //     console.log(error)
    //   }
    //   let than = this
    // }
  },
  methods:{
    setInitRoutes(roots, userType) {
      let arr = []
      if(userType ==1 || userType == 2) {
        roots.forEach(item => {
          if(item.id) {
            arr.push(item.id)
            if(item.children) {
                item.children.forEach((item2) => {
                  arr.push(item2.id)
                  if(item2.children) {
                    item2.children.forEach((item3) => {
                      arr.push(item3.id)
                    })
                  }
                })
            }
          }
        })
        return arr
      }else { // 普通成员
        arr = ['00', '01','010', '02', '020']
      }
      return arr
    },
    filterRoute(roots, arr) {
      roots.forEach(function(item,index){
        if(item.children) { //有二级菜单
          item.children.forEach(function(item2, index2) {
            if(arr.includes(item2.id)) {
              item2.visible = true
            }else {
              item2.visible = false
            }
            if(item2.children) { // 三级菜单
               item2.children.forEach(function(item3, index3){
                 if(arr.includes(item3.id)) {
                    item3.visible = true
                  }else {
                    item3.visible = false
                  }
               })
            }
          })
        }else {
          if(arr.includes(item.id)) {
            item.visible = true
          }else {
            item.visible = false
          }
        }
      });

      console.log('roots', roots)
      roots.forEach(item => {
        if(item.children) {
          if(item.children.filter(it => it.visible && it.visible === true).length) {
           item.visible = true
          }else {
            item.visible = false
          }
        }
        // if(item.children && item.children.filter(it => it.visible && it.visible === true).length) {
        //   item.visible = true
        // }
      })
      console.log(roots, '加了角色菜单筛选后')
      return roots
    }
  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  width: 208px;
  left: 0;
  top: 68px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  display:none;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 208px;
}

.sidebar > ul {
  height: 100%;
}
.sidebar .el-menu{
  border-right:none;
}
.title {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* .el-menu-item {
  border-left: #fff solid 6px;
} */
/* 设置鼠标悬停时el-menu-item的样式 */
/* .el-menu-item:hover {
  border-left: #6190e8 solid 6px !important;
  background-color: #e2eff9 !important;
  color: #6190e8 !important;
} */

/* .el-menu-item:hover i {
  color: #6190e8;
} */

/* 设置选中el-menu-item时的样式 */
.el-menu-item,
.el-submenu {
  /* opacity: 0.8; */
}
.el-menu-item .is-active {
  opacity: 1;
}
.memberRoot{
  display: none;
}
</style>
